import { useCallback, useMemo } from 'react';
import { keyBy } from 'lodash';
import { useParams } from 'react-router-dom';

import { isUsernameOrg } from '@inspiren-monorepo/util-users';

import { getUsersTableFields } from './helpers/getUsersTableFields';
import useUsersUpsert from './hooks/useUsersUpsert';

import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import { useOrganizationRolesMap } from '../../../../hooks/useOrganizationRolesMap';
import { useUnits } from '../../hooks/useUnits';
import ImportTable from '../ImportTable';
import { mapCsvRowsToFields } from '../ImportTable/helpers/mapCsvRowsToFields';
import csvCellMappers from '../ImportTable/utils/csvCellMappers';

import type { FieldTypes } from './helpers/getUsersTableFields';
import type { DataFields } from '../../types/DataFields';
import type {
  FieldsConfiguration,
  Importable,
} from '../ImportTable/types/importable';
import type { UploadFn } from '../ImportTable/types/uploadFn';

const UsersImportTable = () => {
  const { org } = useParams<{ org: string }>();
  const { isAdmin } = useIsAdmin();

  const usernameOrg = isUsernameOrg(org);

  const { data: units, isLoading: isLoadingUnits } = useUnits({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const unitsMap = useMemo(() => keyBy(units || [], 'id'), [units]);

  const { data: roleMap = {}, isLoading: isLoadingRoles } =
    useOrganizationRolesMap(org);

  const fieldsConfiguration: FieldsConfiguration<FieldTypes> = useMemo(
    () => ({
      firstName: {
        csvHeader: 'first name',
        csvHeaderRegExp: /^first( name)?$/i,
        mapper: csvCellMappers.String,
      },
      lastName: {
        csvHeader: 'last name',
        csvHeaderRegExp: /^last( name)?$/i,
        mapper: csvCellMappers.String,
      },
      username: usernameOrg
        ? {
            csvHeader: 'username',
            mapper: csvCellMappers.String,
          }
        : undefined,
      email: {
        csvHeader: 'email',
        mapper: csvCellMappers.String,
      },
      role: {
        csvHeader: 'role',
        mapper: csvCellMappers.Role(roleMap),
      },
      password: {
        csvHeader: 'password',
        mapper: csvCellMappers.String,
      },
      levelAccess: {
        csvHeader: 'level access',
        mapper: csvCellMappers.LevelAccess,
      },
      unitId: {
        csvHeader: 'unit',
        mapper: csvCellMappers.Unit(units),
      },
    }),
    [roleMap, units, org],
  );

  const { handleAddSubmit } = useUsersUpsert({
    orgId: org,
    roleMap,
    unitsMap,
  });

  const columns: DataFields<FieldTypes> = useMemo(
    () =>
      getUsersTableFields(isAdmin, roleMap, unitsMap, org).filter(
        (e) => !!fieldsConfiguration[e.field],
      ),
    [isAdmin, roleMap, org, fieldsConfiguration, unitsMap],
  );

  const mapCsvToFields = useCallback(
    async (csvRows: string[][]): Promise<Importable<FieldTypes>[]> =>
      mapCsvRowsToFields(csvRows, fieldsConfiguration, () => false),
    [fieldsConfiguration],
  );

  const createDefaultRow = useCallback(
    (): FieldTypes =>
      ({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        role: '',
        password: '',
        levelAccess: undefined,
        unitId: '',
        beacon: '',
        orgId: org,
      }) as unknown as FieldTypes,
    [org],
  );

  const upload: UploadFn<FieldTypes> = useCallback(
    async (item) => {
      const {
        $meta: { alreadyExists },
        ...rest
      } = item;

      if (alreadyExists) {
        throw new Error('Users bulk update is not supported');
      }

      await handleAddSubmit({ ...rest, org }, false);
    },
    [handleAddSubmit],
  );

  return (
    <ImportTable
      fields={columns}
      createDefaultRow={createDefaultRow}
      mapCsvToFields={mapCsvToFields}
      loading={isLoadingRoles || isLoadingUnits}
      upload={upload}
      itemName='users'
      heading={`Import users for org ${org}`}
    />
  );
};

export default UsersImportTable;
