import { isEmpty } from 'lodash';
import * as yup from 'yup';

import { hours } from '../../../../../utility/constants';
import { extractBuildingFloorFromDomainId } from '../../../../../utility/helpers/helpers';
import { RenderEscalationValues } from '../../../components/RenderEscalationValues';
import { returnEscTooltipLabel } from '../../../modals/helpers/returnTooltipLabel';

import type { DataFields } from '../../../types/DataFields';
import type { UnitFieldTypes } from '../types/UnitFieldTypes';

const numberSchema = yup
  .number()
  .typeError('This setting must be a number')
  .min(0)
  .integer('This setting must be a whole number')
  // Empty string is also an acceptable value
  .transform((value, originalValue) => (originalValue === '' ? null : value))
  .nullable();

const escNumberSchema = yup.object({
  assigned: yup
    .number()
    .typeError('This setting must be a number')
    .integer('This setting must be a whole number')
    // Empty string is also an acceptable value
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
  unit: yup
    .number()
    .typeError('This setting must be a number')
    .integer('This setting must be a whole number')
    // Empty string is also an acceptable value
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
  building: yup
    .number()
    .typeError('This setting must be a number')
    .integer('This setting must be a whole number')
    // Empty string is also an acceptable value
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
});

export const unitFields: DataFields<UnitFieldTypes> = [
  {
    field: 'id',
    label: 'ID',
    width: 'hidden',
  },
  {
    field: 'orgId',
    label: 'Organization',
    width: 150,
    editType: 'special',
    schema: yup.string().required('You must select an organization'),
    initialValue: import.meta.env.VITE_ORG_ID,
  },
  {
    field: 'domainId',
    label: 'Domain ID',
    width: 'hidden',
  },
  {
    field: 'floorId',
    label: 'Building-Floor',
    width: 200,
    editType: 'special',
    schema: yup.string().required('You must select a building and floor'),
    valueGetter: (params) =>
      extractBuildingFloorFromDomainId(params.row.domainId),
  },
  {
    field: 'name',
    label: 'ID',
    width: 150,
    editType: 'text',
    editable: false,
    schema: yup
      .string()
      .required('You must provide an ID')
      .matches(
        /^[\d.a-z]+$/i,
        'ID can only include letters, numbers, and periods.',
      ),
  },
  {
    field: 'displayName',
    label: 'Display name',
    width: 180,
    editType: 'text',
    initialValue: '',
    schema: yup.string().notRequired(),
  },
  {
    field: 'address',
    label: 'Address',
    width: 180,
    editType: 'text',
    disabled: true,
    hideOnAdd: true,
  },
  {
    field: 'virtualCurtain',
    label: 'Virtual Curtain',
    width: 160,
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'disableAugi',
    label: 'Disable AUGi',
    width: 160,
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'audibleMessages',
    label: 'Audible Messages',
    width: 160,
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'webFallAlertSoundLoop',
    label: 'Web Fall Alert Sound Loop',
    width: 200,
    editType: 'boolean',
    hideOnAdd: true,
    tooltip: 'Play alert sound until FALL notif expires or is resolved',
  },
  {
    field: 'nightLowFallRisk',
    label: 'Night Low Fall Risk',
    width: 160,
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'checkOffline',
    label: 'Offline Check',
    width: 160,
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'fallRiskReset',
    label: 'Reset Fall Risk',
    width: 160,
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'displayCareTeam',
    label: 'Display Care Team',
    width: 160,
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'hide',
    label: 'Hide',
    width: 160,
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'virtualCurtainDurationOptions',
    label: 'Virtual curtain duration options',
    width: 220,
    editType: 'special',
    hideOnAdd: true,
    initialValue: [],
    renderCell: ({ value }) =>
      value?.map((num: string) => `${num} min`).join(', '),
    schema: yup.array(numberSchema),
  },
  {
    field: 'warningThreshold',
    label: 'Warning threshold (day)',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
  },
  {
    field: 'nightWarningThreshold',
    label: 'Warning threshold (night)',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
  },
  {
    field: 'roundingThreshold',
    label: 'Rounding threshold',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
  },
  {
    field: 'nightModeThreshold',
    label: 'Night mode threshold',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
  },
  {
    field: 'nightStartHour',
    label: 'Night mode start',
    width: 200,
    editType: 'select',
    hideOnAdd: true,
    options: hours,
    renderCell: ({ value }) =>
      hours.find((hour) => hour.value === value)?.label,
  },
  {
    field: 'nightEndHour',
    label: 'Night mode end',
    width: 200,
    editType: 'select',
    hideOnAdd: true,
    options: hours,
    renderCell: ({ value }) =>
      hours.find((hour) => hour.value === value)?.label,
  },
  {
    field: 'imageExp',
    label: 'Image deletion timeframe',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
    renderCell: ({ value }) => value && `${value} days`,
  },
  {
    field: 'eventExp',
    label: 'Event deletion timeframe',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
    renderCell: ({ value }) => value && `${value} days`,
  },
  {
    field: 'escalateFall',
    label: 'Fall Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateFall'),
    renderCell: ({ value }) => RenderEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateLB',
    label: 'Leaving Bed Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateLB'),
    renderCell: ({ value }) => RenderEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateOOC',
    label: 'Out Of Chair Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateOOC'),
    renderCell: ({ value }) => RenderEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateHidden',
    label: 'Hidden Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateHidden'),
    renderCell: ({ value }) => RenderEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateUrgent',
    label: 'Urgent Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateUrgent'),
    renderCell: ({ value }) => RenderEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateWarning',
    label: 'Warning Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateWarning'),
    renderCell: ({ value }) => RenderEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateBathroom',
    label: 'Bathroom Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateBathroom'),
    renderCell: ({ value }) => RenderEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateUnit',
    label: 'Listen To Other Unit Escalations',
    width: 225,
    editType: 'boolean',
    hideOnAdd: true,
    tooltip: returnEscTooltipLabel('escalateUnit'),
  },
  {
    field: 'disableAugiAlertInterval',
    label: 'Disable AUGi Alert Interval',
    width: 225,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
    renderCell: ({ value }) => (value ? `${value} hours` : 'Off'),
    tooltip:
      'Number of hours between mobile app reminder alerts for disabled AUGis. 0 or blank to turn off reminders.',
  },
  {
    field: 'bathroomAlertThreshold',
    label: 'Bathroom Alert Threshold',
    width: 225,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
    renderCell: ({ value }) => (!isEmpty(value) ? `${value} minutes` : 'Off'),
    tooltip:
      'Number of minutes after which to send an alert if the bathroom is occupied. 0 and alert will be sent immediately. Leave blank and no alert will be sent.',
  },
];
