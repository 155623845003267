import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { keyBy, sortBy } from 'lodash';

import { getUnits } from '../../data-access/getUnits';
import { ControlledAutocomplete } from '../ControlledAutocomplete';

import type { Control } from 'react-hook-form';

interface Props {
  control: Control;
  org?: string;
}

const SelectBuildingFloorUnit = ({ control, org }: Props) => {
  const { isLoading: unitsLoading, data: units } = useQuery({
    queryKey: ['units'],
    queryFn: getUnits,
  });

  const unitById = useMemo(() => keyBy(units || [], 'id'), [units]);

  const items = useMemo(
    () =>
      units && org
        ? sortBy(units || [], 'domainId')
            .filter((unit) => unit.orgId === org)
            .map((unit) => unit.id)
        : [],
    [units, org],
  );

  return (
    <ControlledAutocomplete
      id='unitId'
      label='Building-Floor-Unit'
      items={items}
      getOptionLabel={(unitId) =>
        unitById[unitId].domainId.replace(`${org}-`, '')
      }
      loading={unitsLoading}
      control={control}
    />
  );
};

export default SelectBuildingFloorUnit;
