import { Api } from '../../../utility/client';

import type { NotifRes } from '../../../../types';

export interface ResolveNotifProps {
  roomId: string;
  notificationId: number;
  user: { domainId: string; firstName: string; lastName: string };
}

export const resolveNotification = async (
  data: ResolveNotifProps,
): Promise<NotifRes[]> => {
  try {
    const { roomId, notificationId, user } = data;
    const notifId = new Date(notificationId * 1000).toISOString();

    const res = await Api.put(`/v1/notifications/${notifId}/resolve`, {
      user,
      roomId,
      source: 'web',
    });

    return res && res.data;
  } catch (error) {
    console.error('error resolving notification', error);
    throw error;
  }
};
