import { Api } from '../../../utility/client';

import type { UnitRes } from '../../../../types';

export const getCustomUnit = async (unitId: string): Promise<UnitRes> => {
  try {
    const res = await Api.get<UnitRes>(`/telesitting/v1/rooms/${unitId}`);
    return res && res?.data;
  } catch (error) {
    console.error('error requesting custom unit');
    throw error;
  }
};
