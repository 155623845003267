import { isDate, isString } from 'lodash';

import { logger } from '@inspiren-monorepo/util-logger';

export function safeToIsoString<T extends undefined | null>(
  date?: Date | string | null,
  emptyValue?: T,
): string | T {
  try {
    if (isDate(date)) return date.toISOString();
    if (isString(date)) return new Date(date).toISOString();
    return emptyValue as T;
  } catch (error) {
    logger.error(`Failed to convert date ${date} to ISO string`, error);
    return emptyValue as T;
  }
}
