import { useCallback, useMemo } from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import * as Sentry from '@sentry/react';
import { format, isAfter, isBefore } from 'date-fns';
import { format as formatTZ } from 'date-fns-tz';
import { isEmpty, isNil, sortBy } from 'lodash';

import { getPositionFromDate } from '@inspiren-monorepo/shared-react/browser';
import { UserEventType } from '@inspiren-monorepo/shared-types';

import ColorSwitch from './ColorSwitch';
import { DataGridBase } from './DataGridBase';
import DisabledTimeLink from './DisabledTimeLink';
import TableMenu from './TableMenu';
import TableRowMenu from './TableRowMenu';

import { sendAmpEvent } from '../../../utility/amplitude';
import { makeEventReviewFilename } from '../../../utility/helpers/helpers';
import {
  dateTimeFormat,
  dateTimeFormatNoYear,
  timeFormat,
} from '../../../utility/helpers/time';
import { formatRoomActivityTypeText } from '../helpers/formatRoomActivityTypeText';
import { useStaffEvents } from '../hooks/useStaffEvents';
import { useTimestamp } from '../hooks/useTimestamp';
import { useUserEvents } from '../hooks/useUserEvents';
import { useEventReviewStore } from '../store/EventReviewStore';

import type { RoomActivityType } from '../helpers/formatRoomActivityTypeText';

interface Props {
  showDate?: boolean;
  showRowMenu: boolean;
}

const DURATION_EVENTS = [UserEventType.PrivacyModeOn, 'Staff Event'];

const EventsTable = ({ showDate = false, showRowMenu }: Props) => {
  const {
    selectedRoom,
    startDate,
    endDate,
    images,
    showStaffEventMarks,
    setShowStaffEventMarks,
    setPosition,
  } = useEventReviewStore();

  const timezone = formatTZ(new Date(), 'zzz');

  const handleSetTimeStamp = useCallback(
    (timestamp: Date) => {
      if (isNil(images)) return;
      const position = getPositionFromDate(images, timestamp) || 0;
      setPosition(position);
    },
    [images, setPosition],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'event',
        headerName: 'Event',
        flex: 2,
        renderCell: (params: any) => (
          <Typography variant='body2'>
            {formatRoomActivityTypeText(params.value)}
          </Typography>
        ),
      },
      {
        field: 'time',
        headerName: 'Time',
        flex: 2,
        valueFormatter: (params: any) => {
          const formatString = showDate ? dateTimeFormatNoYear : timeFormat;
          return format(params.value, formatString);
        },
        renderCell: (params: any) => {
          if (
            startDate &&
            params.value &&
            (params.value < startDate || isEmpty(images))
          ) {
            return (
              <DisabledTimeLink
                tooltipText={isEmpty(images) ? '' : 'Outside of selected range'}
              >
                {params.formattedValue}
              </DisabledTimeLink>
            );
          }

          return (
            <Link
              component='button'
              onClick={() => handleSetTimeStamp(params.value)}
            >
              {params.formattedValue}
            </Link>
          );
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 3,
      },
      {
        field: 'duration',
        headerName: 'Duration',
        flex: 2,
        filterable: false,
        sortable: false,
      },
      {
        field: 'spotlight',
        headerName: 'Spotlight',
        flex: 1,
        filterable: false,
        sortable: false,
        hide: !showRowMenu,
        renderCell: (params: any) => (
          <TableRowMenu
            id={params.row.id}
            eventStart={params.row.time}
            eventEnd={
              params.row.event.includes(DURATION_EVENTS)
                ? params.row.timeOut
                : params.row.time
            }
          />
        ),
      },
    ],
    [startDate, endDate, images, showDate, showRowMenu],
  );

  // hooks

  const { data: events, isLoading, isError } = useStaffEvents();

  const {
    data: userEvents,
    isLoading: userEventsIsLoading,
    isError: userEventsIsError,
  } = useUserEvents();

  const timestamp = useTimestamp();
  const theme = useTheme();
  const backgroundColor = theme.palette.staffEvent.light;

  // useMemo
  const rows = useMemo(
    () => sortBy([...events, ...userEvents], 'time'),
    [events, userEvents],
  );

  const eventsExist = useMemo(
    () =>
      (events && events.length > 0) || (userEvents && userEvents.length > 0),
    [events, userEvents],
  );

  const showToggle = useMemo(
    () => eventsExist && !isNil(images) && images.length > 0,
    [eventsExist, images],
  );

  const roomActivityCSVData = useMemo(
    () =>
      rows?.map((row) => ({
        Event: formatRoomActivityTypeText(row.event as RoomActivityType),
        [`Time in (${timezone})`]: row.time && format(row.time, dateTimeFormat),
        Name: row.name,
        Duration: row.duration,
      })) || [],
    [rows, timezone],
  );

  const isRowActive = useCallback(
    (event: {
      time: Date | null;
      timeIn: Date | null;
      timeOut: Date | null;
    }) => {
      const startTime = event.time;
      const endTime = event.timeOut;

      if (!timestamp) {
        return false;
      }

      if (
        startTime &&
        endTime &&
        !isBefore(timestamp, startTime) &&
        !isAfter(timestamp, endTime)
      ) {
        return true;
      }

      if (startTime && !endTime && !isBefore(timestamp, startTime)) {
        return true;
      }

      return false;
    },
    [timestamp],
  );

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        data-testid='eventreview-eventstable-header'
      >
        <Typography variant='h5' component='h3' p={1.5}>
          Room Activity
        </Typography>
        <ColorSwitch
          checked={showToggle ? showStaffEventMarks : false}
          disabled={!showToggle}
          onChange={() => {
            setShowStaffEventMarks(!showStaffEventMarks);

            sendAmpEvent('Toggled Staff Events', {
              toggle: showStaffEventMarks,
            });
          }}
          inputProps={{ 'aria-label': 'controlled' }}
          size='small'
          customColor={(theme) => theme.palette.staffEvent.main}
        />
        {eventsExist && (
          <TableMenu
            id='staff-events'
            csvData={roomActivityCSVData}
            filename={
              selectedRoom &&
              startDate &&
              endDate &&
              makeEventReviewFilename(
                'Room_Activity',
                selectedRoom,
                startDate,
                endDate,
              )
            }
          />
        )}
      </Box>
      <DataGridBase
        noRowsLabel='No room activity in range'
        rows={rows}
        columns={columns}
        loading={isLoading || userEventsIsLoading}
        error={isError || userEventsIsError}
        getRowClassName={(params) => {
          if (isRowActive(params.row)) {
            return 'row-active--true';
          }

          return '';
        }}
        backgroundColor={backgroundColor}
      />
    </>
  );
};

export default Sentry.withProfiler(EventsTable);
