import { isNil } from 'lodash';
import { useWatch } from 'react-hook-form';

import { DomainId } from '@inspiren-monorepo/util-rooms';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { ControlledAutocomplete } from '../../modals/ControlledAutocomplete';
import { useCareLevels } from '../CareLevelsTable/hooks/useCareLevels';

import type { Control } from 'react-hook-form';

interface Props {
  control: Control;
  unitsMap: Record<string, AdminTypes.UnitDto>;
  org?: string;
}

export const SelectCareLevel = ({ control, unitsMap, org }: Props) => {
  const unitId = useWatch({ name: 'unitId', control });
  const unit = unitId ? unitsMap[unitId] : undefined;
  const building = unit ? DomainId.toBuildingId(unit.domainId) : undefined;

  const { data: careLevels = [], isLoading } = useCareLevels(org, building);

  return (
    <ControlledAutocomplete
      id='careLevel'
      label='Care Level'
      items={careLevels.map((careLevel) => careLevel.id)}
      loading={isLoading}
      disabled={isNil(org)}
      control={control}
      getOptionLabel={(id) =>
        careLevels.find((careLevel) => careLevel.id === id)?.displayName || id
      }
    />
  );
};
