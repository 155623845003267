import { useMemo } from 'react';

import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

const tabs = [
  'basestations',
  'beacons',
  'buildings',
  'care-levels',
  'floors',
  'organizations',
  'rooms',
  'units',
  'users',
] as const;

export const useAccessibleAdminTabs = (
  user: TelesittingTypes.User | null | undefined,
) =>
  useMemo(
    () =>
      tabs.filter((tab) =>
        user?.ability?.can('view', `virtual-care.admin.${tab}`),
      ),
    [user],
  );
