import type {
  MqttCredentials,
  AdminTypes,
} from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from './client';

import type { Basestation } from '../../types';

// TODO: Finish breaking this out into seperate files in appropriate `data-access` folders

export const putBasestation = async (
  base: Omit<Basestation, 'audible'>,
): Promise<Basestation> => {
  try {
    const res = await Api.put(`/admin/v1/basestations/${base.id}`, base);

    return res && res.data;
  } catch (error) {
    console.error('error updating basestation');
    throw error;
  }
};

export const postBasestation = async (
  base: Omit<Basestation, 'audible'>,
): Promise<Basestation> => {
  try {
    const res = await Api.post('/admin/v1/basestations', base);

    return res && res.data;
  } catch (error) {
    console.error('error adding basestation');
    throw error;
  }
};

export const putRoom = async (
  room: AdminTypes.EditRoom,
): Promise<AdminTypes.Room> => {
  try {
    const { id, ...editRoom } = room;

    const res = await Api.put<AdminTypes.Room>(
      `/admin/v1/rooms/${id}`,
      editRoom,
    );

    return res && res.data;
  } catch (error) {
    console.error('error updating room');
    throw error;
  }
};

export const postRoom = async (
  room: AdminTypes.NewRoom,
): Promise<AdminTypes.Room> => {
  try {
    const res = await Api.post<AdminTypes.Room>('/admin/v1/rooms', room);
    return res && res.data;
  } catch (error) {
    console.error('error adding room');
    throw error;
  }
};

export const putBuilding = async (
  building: AdminTypes.BuildingUpdateDto,
): Promise<AdminTypes.BuildingDto> => {
  try {
    const res = await Api.put(`/admin/v1/buildings/${building.id}`, building);

    return res && res.data;
  } catch (error) {
    console.error('error editing building');
    throw error;
  }
};

export const postBuilding = async (
  building: AdminTypes.BuildingCreateDto,
): Promise<AdminTypes.BuildingDto> => {
  try {
    const res = await Api.post('/admin/v1/buildings', building);

    return res && res.data;
  } catch (error) {
    console.error('error adding building');
    throw error;
  }
};

export const getFloors = async () => {
  try {
    const res = await Api.get<AdminTypes.FloorDto[]>('/admin/v1/floors');
    return res && res.data;
  } catch (error) {
    console.error('error getting floors');
    throw error;
  }
};

export const postFloor = async (floor: AdminTypes.FloorCreateDto) => {
  try {
    const res = await Api.post<AdminTypes.FloorDto>('/admin/v1/floors', floor);
    return res && res.data;
  } catch (error) {
    console.error('error adding floor');
    throw error;
  }
};

export const getMqttCreds = async () => {
  try {
    const res = await Api.post<MqttCredentials>('/v1/users/mqtt', {});
    return res.data;
  } catch (error) {
    console.error('error getting mqtt creds');
    throw error;
  }
};
