import { useMemo } from 'react';
import { Button, IconButton, Popover, Stack } from '@mui/material';
import { MoreVertOutlined as MenuIcon } from '@mui/icons-material';
import {
  bindTrigger,
  bindPopover,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import { BasestationIdDisplay } from './components/BasestationIdDisplay';
import { BathroomActivitySwitch } from './components/BathroomActivitySwitch';
import DisableAugiButton from './components/DisableAugiButton';
import FallRiskToggle from './components/FallRiskToggle';
import { TriggerVirtualBsActionButton } from './components/TriggerVirtualBsActionButton';
import { useLastImagesEventReviewUrl } from './hooks/useLastImagesEventReviewUrl';
import { useVirtualBsActions } from './hooks/useVirtualBsActions';

import { useCurrentUser } from '../../../../HOC/CurrentUserContextProvider';
import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import { Can } from '../../../Can/Can';
import { useUnits } from '../../hooks/useUnits';

import type { FallRiskLevel } from '../../../../../types';

interface Props {
  roomId: string;
  roomDomainId: string;
  baseId: string;
  orgId: string;
  roomName: string;
  fallRiskLevel: FallRiskLevel;
  onPrivacyModeChange: () => void;
  onToggleZoneUpdate: () => void;
  offline: boolean;
  privacy: boolean;
  augiDisabled: boolean;
  unitId: string;
  isDemoSession?: boolean;
  isDemoAction?: boolean;
  bathroomSensorEnabled: boolean;
  bathroomAlertsEnabled?: boolean;
  keepAlive?: string;
}

const RoomMenu = ({
  roomId,
  roomDomainId,
  baseId,
  orgId,
  roomName,
  fallRiskLevel,
  onPrivacyModeChange,
  onToggleZoneUpdate,
  offline,
  privacy,
  augiDisabled,
  unitId,
  isDemoSession,
  isDemoAction,
  bathroomSensorEnabled,
  bathroomAlertsEnabled,
  keepAlive,
}: Props) => {
  const { user } = useCurrentUser();
  const { units } = useUnits();
  const { isAdmin } = useIsAdmin();

  const { actions, isLoading: isLoadingActions } = useVirtualBsActions(
    baseId,
    isDemoSession,
  );

  const { eventReviewUrl, isLoadingEventReviewUrl } =
    useLastImagesEventReviewUrl(roomDomainId, roomName, offline, keepAlive);

  const unitAllowsDisableAugi = useMemo(() => {
    const unit = units?.find((u) => `Room-${u.domainId}` === unitId);
    return unit?.disableAugi || false;
  }, [unitId, units]);

  const unitAllowsNightLow = useMemo(() => {
    const unit = units?.find((u) => `Room-${u.domainId}` === unitId);
    return unit?.nightLowFallRisk || false;
  }, [unitId, units]);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'room-menu',
  });

  return (
    <>
      <IconButton
        {...bindTrigger(popupState)}
        sx={{
          color: 'white',
          height: 36,
          width: 36,
          backgroundColor: 'grey.800',
          ':hover': { backgroundColor: 'grey.900' },
        }}
        size='large'
        // Disable the button if the menu is empty
        disabled={
          !user?.ability?.can('view', 'global.fall-risk-high-low') &&
          privacy &&
          !user?.ability?.can('view', 'global.disable-augi')
        }
      >
        <MenuIcon fontSize='inherit' />
      </IconButton>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Stack width={275} m={1} gap={1} alignItems='center'>
          <Can
            permission={{
              subject: 'global.fall-risk-high-low',
              action: 'view',
            }}
          >
            <FallRiskToggle
              orgId={orgId}
              roomId={roomId}
              roomDomainId={roomDomainId}
              roomName={roomName}
              fallRiskLevel={fallRiskLevel}
              nightLowFallRisk={unitAllowsNightLow}
            />
          </Can>

          {bathroomSensorEnabled && (
            <Can
              permission={{
                subject: 'global.bathroom-alert-room-toggle',
                action: 'view',
              }}
            >
              <BathroomActivitySwitch
                bathroomAlertsEnabled={bathroomAlertsEnabled || false}
                roomId={roomId}
                roomDomainId={roomDomainId}
              />
            </Can>
          )}

          {privacy && (
            <Button
              size='medium'
              variant='contained'
              color='primary'
              onClick={onPrivacyModeChange}
              fullWidth
            >
              Close Virtual Curtain
            </Button>
          )}

          {isAdmin && !privacy && !augiDisabled && !offline && (
            <Button
              size='medium'
              variant='contained'
              color='primary'
              onClick={onToggleZoneUpdate}
              fullWidth
            >
              Update Zones
            </Button>
          )}

          <Can
            permission={{
              subject: 'virtual-care.event-review',
              action: 'view',
            }}
          >
            <Button
              size='medium'
              variant='contained'
              color='primary'
              href={eventReviewUrl}
              fullWidth
              disabled={offline && isLoadingEventReviewUrl}
            >
              Open in Event Review
            </Button>
          </Can>

          {unitAllowsDisableAugi && (
            <Can
              permission={{ subject: 'global.disable-augi', action: 'view' }}
            >
              <DisableAugiButton
                roomId={roomDomainId}
                augiDisabled={augiDisabled}
              />
            </Can>
          )}

          <BasestationIdDisplay baseId={baseId} isAdmin={isAdmin} />

          {isAdmin &&
            isDemoSession &&
            !isLoadingActions &&
            actions?.map((action) => (
              <TriggerVirtualBsActionButton
                baseId={baseId}
                action={action}
                isRunning={isDemoAction}
              />
            ))}
        </Stack>
      </Popover>
    </>
  );
};

export default RoomMenu;
