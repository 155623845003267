import { queryOptions, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { getCustomUnit } from '../data-access/getCustomUnit';

export const getRoomsQueryOptions = (unitId: string) =>
  queryOptions({
    queryKey: ['customUnits', unitId],
    queryFn: () => getCustomUnit(unitId),
    enabled: !isEmpty(unitId),
    refetchInterval: 3000,
  });

export const useRooms = (unitId: string) =>
  useQuery(getRoomsQueryOptions(unitId));
