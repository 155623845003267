import { Identify, identify, init, track } from '@amplitude/analytics-browser';

import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

export const configAmp = (user: TelesittingTypes.User) => {
  init(
    import.meta.env.VITE_API_ENV === 'production'
      ? import.meta.env.VITE_VC_AMPLITUDE_KEY
      : import.meta.env.VITE_VC_AMPLITUDE_KEY_DEV,
    user.domainId,
    { defaultTracking: true },
  );

  const identifyObj = new Identify();

  identifyObj.set('First Name', user.firstName);
  identifyObj.set('Last Name', user.lastName);
  identifyObj.set('Organization', user.orgId);
  identifyObj.set('Role', user.roleId);
  if (user.unitId) identifyObj.set('Unit', user.unitId);
  if (user.department) identifyObj.set('Department', user.department);

  identify(identifyObj);
};

export const sendAmpEvent = (
  eventType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventProperties: Record<string, any> = {},
) => {
  track(eventType, eventProperties);
};
