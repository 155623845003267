import { includes } from 'lodash';

const FALL_RISK_LEVELS = ['high', 'nightLow', 'low', 'off'] as const;

export type FallRiskLevel = (typeof FALL_RISK_LEVELS)[number];

type FallRiskLevelOption = {
  value: FallRiskLevel;
  label: string;
  description: string;
};

export const FALL_RISK_OPTIONS = {
  high: {
    value: 'high',
    label: 'High',
    description: 'High Fall Risk',
  },
  nightLow: {
    value: 'nightLow',
    label: 'Night',
    description: 'Night Fall Risk',
  },
  low: {
    value: 'low',
    label: 'Low',
    description: 'Low Fall Risk',
  },
  off: {
    value: 'off',
    label: 'Fall Only',
    description: 'Fall Only',
  },
} as const satisfies Record<FallRiskLevel, FallRiskLevelOption>;

export const isValidFallRiskLevel = (value: string): value is FallRiskLevel =>
  includes(FALL_RISK_LEVELS, value);
