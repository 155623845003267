import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { DomainId } from '@inspiren-monorepo/util-rooms';

import { getAllUnits } from '../data-access/getAllUnits';

// TODO: Get only single unit
// TODO: Update to use room rds id
export const useUnitForRoom = (roomDomainId: string) => {
  const { data: units } = useQuery({
    queryKey: ['getAllUnits'],
    queryFn: () => getAllUnits(),
  });

  return useMemo(() => {
    if (!units) return undefined;
    const unitDomainId = DomainId.toUnitId(roomDomainId);
    return units?.find((u) => u.domainId === unitDomainId);
  }, [roomDomainId, units]);
};
