import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import type { GridActionsColDef, GridRowParams } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { isNil, keyBy } from 'lodash';
import toast from 'react-hot-toast';

import { ControlledError } from '@inspiren-monorepo/shared-types';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';
import type { GetRoomSiteConfigData } from '@inspiren-monorepo/virtual-care/db';

import { SelectCareLevel } from './SelectCareLevel';
import getRoomsTableFields from './constants/getRoomsTableFields';
import { useRoomsSiteConfigs } from './hooks/useRoomsSiteConfigs';
import useRoomsUpsert from './hooks/useRoomsUpsert';
import { useSiteConfigHistory } from './hooks/useSiteConfigHistory';

import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import {
  extractOrgFromRoomId,
  extractRoomNumberFromRoomId,
} from '../../../../utility/helpers/id';
import { BaseHistoryModal } from '../../components/BaseHistoryModal';
import BulkImportLink from '../../components/BulkImportLink';
import OrgHeader from '../../components/OrgHeader';
import OrgSelector from '../../components/OrgSelector';
import { getOrgs } from '../../data-access/getOrgs';
import { useOrgRooms } from '../../hooks/useOrgRooms';
import { useUnits } from '../../hooks/useUnits';
import DisplayName from '../../modals/special/DisplayName';
import SelectBuildingFloorUnit from '../../modals/special/SelectBuildingFloorUnit';
import SelectRoomDesignation from '../../modals/special/SelectRoomDesignation';
import SelectRoomFallRisk from '../../modals/special/SelectRoomFallRisk';
import useBasestationUpsert from '../BasestationsTable/hooks/useBasestationUpsert';
import { useCareLevels } from '../CareLevelsTable/hooks/useCareLevels';
import TableBase from '../TableBase';

import type { RoomFieldTypes } from './types/RoomFieldTypes';
import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';

export type TableView = 'Default View' | 'Site Config View';

const RoomsTable = () => {
  const awsOrg = import.meta.env.VITE_ORG_ID;
  const { isAdmin } = useIsAdmin();

  const [selectedOrgId, setSelectedOrgId] = useState<string | undefined>(
    awsOrg,
  );

  const [tableView, setTableView] = useState<TableView>('Default View');

  const handleTableViewChange = useCallback((value: TableView | null) => {
    if (!value) return;
    setTableView(value);
  }, []);

  const {
    isLoading: orgsLoading,
    data: orgs,
    isError: orgsError,
  } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const selectedOrg = useMemo(
    () => (orgs || []).find((org) => org.id === selectedOrgId),
    [selectedOrgId, orgs],
  );

  const orgId = selectedOrg?.id;

  const { isLoading: careLevelsLoading, data: careLevels } = useCareLevels(
    selectedOrg?.id,
  );

  const careLevelsById = useMemo(
    () => keyBy(careLevels || [], 'id'),
    [careLevels],
  );

  const {
    isLoading: unitsLoading,
    isError: unitsError,
    data: units,
  } = useUnits();

  const unitsMap = useMemo(() => keyBy(units || [], 'id'), [units]);

  const {
    isLoading: roomsLoading,
    isError: roomsError,
    rooms,
  } = useOrgRooms({ orgId, includeDeleted: true });

  const { siteConfigs } = useRoomsSiteConfigs(selectedOrg?.structureId || '');
  const { basestations } = useBasestationUpsert();
  const { handleAddSubmit, handleEditSubmit } = useRoomsUpsert(unitsMap);

  const handleOrgChange = useCallback(
    (
      _e: React.ChangeEvent<object>,
      newValue: AdminTypes.OrganizationDto | null,
    ) => {
      setSelectedOrgId(newValue?.id);
    },
    [],
  );

  const roomsTableFields = useMemo(
    () => getRoomsTableFields(unitsMap, undefined, tableView),
    [tableView, unitsMap],
  );

  const data = useMemo(
    () =>
      (rooms || []).map(
        ({
          id,
          domainId,
          fallRiskLevel,
          careLevelId,
          displayName,
          hide,
          deleted,
          designation,
          unitId,
          buildingDisplayName,
          floorNumber,
          unitDisplayName,
        }) => {
          // TODO: can join this data when pulling from db https://linear.app/inspiren/issue/INS-695/join-this-data-when-pulling-from-db
          const siteConfig = siteConfigs?.find(
            (config: GetRoomSiteConfigData) => config.domainId === domainId,
          );

          const baseId = basestations?.find(
            (base) => base.room === domainId,
          )?.id;

          return {
            id,
            baseID: baseId || '',
            structureId: siteConfig?.id,
            ...siteConfig?.configOverrides,
            roomId: extractRoomNumberFromRoomId(domainId) || '',
            organization: extractOrgFromRoomId(domainId) || '',
            unitId,
            fallRiskLevel,
            careLevel: careLevelId
              ? careLevelsById[careLevelId]?.displayName || ''
              : '',
            displayName,
            hide,
            deleted,
            designation,
            buildingDisplayName,
            floorNumber,
            unitDisplayName,
          };
        },
      ),
    [rooms, careLevelsById, siteConfigs, basestations],
  );

  const onEditSubmit: OnSubmitFormModal<RoomFieldTypes> = useCallback(
    async (item) => {
      try {
        await handleEditSubmit({
          ...item,
          organization: selectedOrgId as string,
        });

        toast.success(`Successfully updated room ${item.roomId}`);
      } catch (error) {
        const message =
          error instanceof ControlledError
            ? error.message
            : `Error updating room${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [handleEditSubmit, orgId],
  );

  const onAddSubmit: OnSubmitFormModal<RoomFieldTypes> = useCallback(
    async (item) => {
      try {
        await handleAddSubmit({
          ...item,
          organization: selectedOrgId as string,
        });

        toast.success(`Successfully added room ${item.roomId}`);
      } catch (error) {
        const message =
          error instanceof ControlledError
            ? error.message
            : `Error adding room${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [handleAddSubmit, orgId],
  );

  const renderModal: RenderFormModal<RoomFieldTypes> = useCallback(
    ({ defaultComponents, control }) => (
      <>
        <OrgHeader
          displayName={selectedOrg?.displayName}
          id={selectedOrg?.id}
        />
        {defaultComponents.roomId}
        <DisplayName
          field='displayName'
          label='Display Name'
          control={control}
        />
        <SelectBuildingFloorUnit control={control} org={orgId} />
        {/* TODO: Use switch UI for fall risk similar to room modal */}
        <SelectRoomFallRisk control={control} orgId={orgId} />
        <SelectCareLevel control={control} org={orgId} unitsMap={unitsMap} />
        <SelectRoomDesignation control={control} />
        {defaultComponents.hide}
        {defaultComponents.deleted}
      </>
    ),
    [selectedOrg, orgId, isAdmin, unitsMap],
  );

  const {
    handleClose,
    handleOpen,
    columns,
    data: siteConfigHistoryData,
    selectedRow,
    isLoading: historyIsLoading,
  } = useSiteConfigHistory();

  const siteConfigHistoryModal = useCallback(
    (params: GridRowParams) => (
      <BaseHistoryModal
        title='Site Config History'
        open={selectedRow === params.row.structureId}
        onOpen={() => handleOpen(params)}
        onClose={handleClose}
        rows={siteConfigHistoryData || []}
        loading={historyIsLoading}
        columns={columns}
        error={null}
      />
    ),
    [
      columns,
      siteConfigHistoryData,
      selectedRow,
      handleOpen,
      handleClose,
      historyIsLoading,
    ],
  );

  const siteConfigColumn: GridActionsColDef = useMemo(
    () => ({
      field: 'history',
      type: 'actions',
      width: 50,
      hideable: false,
      getActions: (params: GridRowParams) => [siteConfigHistoryModal(params)],
    }),
    [siteConfigHistoryModal],
  );

  return (
    <Stack spacing={2}>
      {isAdmin && (
        <OrgSelector
          orgs={orgs ?? []}
          loading={orgsLoading}
          value={selectedOrg || null}
          onChange={handleOrgChange}
        />
      )}
      <TableBase<RoomFieldTypes>
        itemName='Room'
        fields={roomsTableFields}
        data={data}
        loading={roomsLoading || careLevelsLoading || unitsLoading}
        renderModal={renderModal}
        modalLoading={orgsLoading}
        modalError={orgsError}
        error={roomsError || unitsError}
        getRowId={(row) => row.id}
        onEditSubmit={onEditSubmit}
        onAddSubmit={onAddSubmit}
        customNoRowsText={isNil(orgId) ? 'No organization selected' : undefined}
        disableAddButton={isNil(orgId)}
        disableEditing={tableView === 'Site Config View'}
        actionComponent={
          tableView === 'Site Config View' ? siteConfigColumn : undefined
        }
        extraToolbarButtons={
          isAdmin && (
            <>
              <BulkImportLink
                disabled={!orgId}
                itemName='rooms'
                urlSuffix={orgId}
              />
              <Stack
                direction='row'
                alignItems='center'
                sx={{ flex: 1, justifyContent: 'flex-end' }}
              >
                <Autocomplete
                  disablePortal
                  id='combo-box-view'
                  options={['Default View', 'Site Config View']}
                  sx={{ width: 200 }}
                  value={tableView}
                  onChange={(_, newValue) =>
                    handleTableViewChange(newValue as TableView | null)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label='View' size='small' />
                  )}
                />
              </Stack>
            </>
          )
        }
      />
    </Stack>
  );
};

export default RoomsTable;
