import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash';
import toast from 'react-hot-toast';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { unitFields } from './constants/unitFields';
import { postUnit } from './data-access/postUnit';
import { putUnit } from './data-access/putUnit';

import { getFloors } from '../../../../utility';
import { extractBuildingFloorFromDomainId } from '../../../../utility/helpers/helpers';
import { getUnits } from '../../data-access/getUnits';
import { useAdminOrg } from '../../hooks/useAdminOrg';
import TableBase from '../TableBase';

import type { UnitFieldTypes } from './types/UnitFieldTypes';
import type { OnSubmitFormModal } from '../../modals/FormModalBase';

const UnitsTable = () => {
  const queryClient = useQueryClient();

  const orgId = import.meta.env.VITE_ORG_ID;

  const {
    data: org,
    isLoading: orgsLoading,
    isError: orgError,
  } = useAdminOrg({ orgId });

  const { isLoading: floorsLoading, isError: floorsError } = useQuery({
    queryKey: ['floors'],
    queryFn: getFloors,
  });

  const {
    isLoading: unitsLoading,
    isError: unitsError,
    data: units,
  } = useQuery({
    queryKey: ['units'],
    queryFn: getUnits,
  });

  const handleEditSubmit: OnSubmitFormModal<UnitFieldTypes> = useCallback(
    async ({
      id,
      floorId,
      domainId,
      name,
      displayName,
      virtualCurtainDurationOptions,
      virtualCurtain,
      warningThreshold,
      roundingThreshold,
      nightModeThreshold,
      nightWarningThreshold,
      nightStartHour,
      nightEndHour,
      disableAugi,
      imageExp,
      eventExp,
      audibleMessages,
      webFallAlertSoundLoop,
      hide,
      escalateFall,
      escalateLB,
      escalateOOC,
      escalateUnit,
      escalateHidden,
      escalateUrgent,
      escalateWarning,
      escalateBathroom,
      disableAugiAlertInterval,
      nightLowFallRisk,
      bathroomAlertThreshold,
      fallRiskReset,
      checkOffline,
      displayCareTeam,
    }) => {
      try {
        const convertToNumber = (value: string | null | undefined) =>
          !isNil(value) ? Number(value) : null;

        const data: AdminTypes.UnitUpdateDto = {
          id,
          floorId,
          domainId,
          displayName,
          virtualCurtain,
          virtualCurtainDurationOptions: virtualCurtainDurationOptions?.map(
            (o) => +o,
          ),
          warningThreshold: convertToNumber(warningThreshold),
          roundingThreshold: convertToNumber(roundingThreshold),
          nightModeThreshold: convertToNumber(nightModeThreshold),
          nightWarningThreshold: convertToNumber(nightWarningThreshold),
          nightStartHour: convertToNumber(nightStartHour) as number,
          nightEndHour: convertToNumber(nightEndHour) as number,
          disableAugi,
          imageExp: convertToNumber(imageExp),
          eventExp: convertToNumber(eventExp),
          audibleMessages,
          webFallAlertSoundLoop,
          nightLowFallRisk,
          fallRiskReset,
          checkOffline,
          displayCareTeam,
          hide,
          escalateFall: {
            assigned: convertToNumber(escalateFall?.assigned),
            unit: convertToNumber(escalateFall?.unit),
            building: convertToNumber(escalateFall?.building),
          },
          escalateLB: {
            assigned: convertToNumber(escalateLB?.assigned),
            unit: convertToNumber(escalateLB?.unit),
            building: convertToNumber(escalateLB?.building),
          },
          escalateOOC: {
            assigned: convertToNumber(escalateOOC?.assigned),
            unit: convertToNumber(escalateOOC?.unit),
            building: convertToNumber(escalateOOC?.building),
          },
          escalateHidden: {
            assigned: convertToNumber(escalateHidden?.assigned),
            unit: convertToNumber(escalateHidden?.unit),
            building: convertToNumber(escalateHidden?.building),
          },
          escalateUrgent: {
            assigned: convertToNumber(escalateUrgent?.assigned),
            unit: convertToNumber(escalateUrgent?.unit),
            building: convertToNumber(escalateUrgent?.building),
          },
          escalateWarning: {
            assigned: convertToNumber(escalateWarning?.assigned),
            unit: convertToNumber(escalateWarning?.unit),
            building: convertToNumber(escalateWarning?.building),
          },
          escalateBathroom: {
            assigned: convertToNumber(escalateBathroom?.assigned),
            unit: convertToNumber(escalateBathroom?.unit),
            building: convertToNumber(escalateBathroom?.building),
          },
          escalateUnit,
          disableAugiAlertInterval: convertToNumber(
            disableAugiAlertInterval,
          ) as number,
          bathroomAlertThreshold: convertToNumber(bathroomAlertThreshold),
        };

        await putUnit(data);

        await queryClient.invalidateQueries({
          queryKey: ['units'],
        });

        toast.success(`Successfully updated unit: ${name || displayName}`);
      } catch (error) {
        toast.error(`Error updating unit${error ? `: ${error}` : ''}`);
      }
    },
    [],
  );

  const handleAddSubmit: OnSubmitFormModal<UnitFieldTypes> = useCallback(
    async ({ floorId, name, displayName, orgId: selectedOrgId }) => {
      try {
        await postUnit({
          floorId,
          name,
          displayName: displayName || undefined,
          orgId: selectedOrgId,
        });

        await queryClient.invalidateQueries({
          queryKey: ['units'],
        });

        toast.success(`Successfully added unit: ${name || displayName}`);
      } catch (error) {
        toast.error(`Error adding unit${error ? `: ${error}` : ''}`);
      }
    },
    [],
  );

  const data = useMemo(
    () =>
      (units || []).map(
        ({
          id,
          domainId,
          name,
          displayName,
          imageExp,
          eventExp,
          disableAugiAlertInterval,
          bathroomAlertThreshold,
          virtualCurtainDurationOptions,
          warningThreshold,
          roundingThreshold,
          nightModeThreshold,
          nightWarningThreshold,
          nightStartHour,
          nightEndHour,
          escalateFall,
          escalateBathroom,
          escalateUrgent,
          escalateWarning,
          escalateHidden,
          escalateLB,
          escalateOOC,
          ...remainingFields
        }) => ({
          id,
          domainId,
          name,
          buildingFloor: extractBuildingFloorFromDomainId(domainId),
          virtualCurtainDurationOptions:
            virtualCurtainDurationOptions?.map((o) => o.toString()) || [],
          displayName: displayName || '',
          warningThreshold: warningThreshold?.toString() || '',
          roundingThreshold: roundingThreshold?.toString() || '',
          nightModeThreshold: nightModeThreshold?.toString() || '',
          nightWarningThreshold: nightWarningThreshold?.toString() || '',
          nightStartHour: nightStartHour?.toString() || '',
          nightEndHour: nightEndHour?.toString() || '',
          imageExp: imageExp?.toString() || '',
          eventExp: eventExp?.toString() || '',
          escalateFall: escalateFall || {},
          escalateLB: escalateLB || {},
          escalateOOC: escalateOOC || {},
          escalateHidden: escalateHidden || {},
          escalateUrgent: escalateUrgent || {},
          escalateWarning: escalateWarning || {},
          escalateBathroom: escalateBathroom || {},
          disableAugiAlertInterval: disableAugiAlertInterval?.toString() || '',
          bathroomAlertThreshold: bathroomAlertThreshold?.toString() || '',
          ...remainingFields,
        }),
      ),
    [units],
  );

  const fieldsToShow = !org?.bathroomSensorEnabled
    ? unitFields.filter(
        (field) =>
          field.field !== 'escalateBathroom' &&
          field.field !== 'bathroomAlertThreshold',
      )
    : unitFields;

  return (
    <TableBase<UnitFieldTypes>
      itemName='Unit'
      fields={fieldsToShow}
      data={data}
      getRowId={({ id }) => id}
      defaultSort='org'
      loading={orgsLoading || unitsLoading}
      modalLoading={floorsLoading}
      modalError={floorsError}
      error={orgError || unitsError}
      onEditSubmit={handleEditSubmit}
      onAddSubmit={handleAddSubmit}
    />
  );
};

export default UnitsTable;
