import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

interface Props {
  orgId: string;
  includeDeleted?: boolean;
  lastKey?: string;
}

export interface RoomsTableQueryResponse {
  rooms: AdminTypes.Room[];
  lastKey: string | undefined;
}

export async function getRoomsByOrg({
  orgId,
  includeDeleted = false,
  lastKey,
}: Props) {
  return Api.get<RoomsTableQueryResponse>(`admin/v1/rooms/all/${orgId}`, {
    params: { includeDeleted, lastKey },
  }).then((res) => res.data);
}
