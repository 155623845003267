import { Link, Stack, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
  GridEditSingleSelectCell,
  getGridStringOperators,
} from '@mui/x-data-grid';
import type { GridComparatorFn } from '@mui/x-data-grid/models/gridSortModel';
import { formatDistanceToNow } from 'date-fns';
import { isEmpty, startCase } from 'lodash';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import {
  isValidMobileNumber,
  PhoneNumberFormat,
  formatPhoneNumberSafe,
} from '@inspiren-monorepo/util-formatters';
import { isUsernameOrg } from '@inspiren-monorepo/util-users';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { getRoleOptions } from './getRoleOptions';
import usersTableSchemas from './usersTableSchemas';

import { Can } from '../../../../Can/Can';
import { Cannot } from '../../../../Can/Cannot';
import GeneralChip from '../../../../shared/GeneralChip';
import importUniqueValidator from '../../ImportTable/validators/importUniqueValidator';
import ImportUnitAutocomplete from '../components/ImportUnitAutocomplete';
import { StaffActivityStatus } from '../components/StaffActivityStatus';
import UnitDisplayName from '../components/UnitDisplayName';
import { UserAssignmentModal } from '../components/UserAssignmentModal';

import type { LevelAccess, RoleMap } from '../../../../../../types';
import type { ImportableDataFields } from '../../ImportTable/types/importable';

YupPassword(yup);

export type FieldTypes = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  mobilePhone?: string;
  password: string;
  role: string;
  unitId: string;
  assignments?: number;
  levelAccess: LevelAccess;
  beacon: string;
  beaconBattery?: number;
  auth0Connection?: string;
  org?: string;
  status?: string;
};

const statusComparator: GridComparatorFn<string> = (v1: string, v2: string) => {
  if (isEmpty(v1)) return 1;
  if (isEmpty(v1) && isEmpty(v2)) return 0;
  if (isEmpty(v2)) return -1;

  return new Date(v2).getTime() - new Date(v1).getTime();
};

const getPasswordSchema = (org?: string, required?: boolean) => {
  const baseSchema: yup.StringSchema<string | null | undefined> = required
    ? yup.string().required('Password is required')
    : yup
        .string()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value,
        )
        .nullable();

  if (org === 'MAPLEWOOD' || org === 'ANTHEM')
    return baseSchema.min(4, 'Password must be at least 4 characters long');

  const strongSchema = baseSchema
    .minLowercase(1, 'Password must contain at least 1 lowercase letter')
    .minUppercase(1, 'Password must contain at least 1 uppercase letter')
    .minNumbers(1, 'Password must contain at least 1 number')
    .minSymbols(1, 'Password must contain at least 1 special character');

  if (org === 'MGH' || org === 'CAREONE')
    return strongSchema.min(8, 'Password must be at least 8 characters long');

  return strongSchema.min(12, 'Password must be at least 12 characters long');
};

export const getUsersTableFields = (
  isAdminRole: boolean,
  roleMap: RoleMap,
  unitsMap: Record<string, AdminTypes.UnitDto>,
  org?: string,
  textMessageAlerts?: boolean,
  staffView = false,
): ImportableDataFields<FieldTypes> => {
  const usernameRequired = !!org && isUsernameOrg(org);

  return [
    {
      field: 'id',
      label: 'ID',
      width: isAdminRole ? 380 : 'hidden',
      editType: 'text',
      editable: false,
      hideOnAdd: true,
      disabledOnImport: () => true,
    },
    {
      field: 'firstName',
      label: 'First Name',
      width: 150,
      editType: 'text',
      schema: yup.string().required('You must provide a first name.'),
    },
    {
      field: 'lastName',
      label: 'Last Name',
      width: 150,
      editType: 'text',
      schema: yup.string().required('You must provide a last name.'),
    },
    {
      field: 'email',
      label: 'Email',
      width: 300,
      editType: 'text',
      editable: true,
      // email is optional for username organization but required for other one
      schema: usersTableSchemas.getEmailSchema(!usernameRequired),
      onImportCellEditValidators: [importUniqueValidator],
    },
    {
      field: 'username',
      label: 'Username',
      width: usernameRequired ? 160 : 'hidden',
      editType: 'text',
      editable: true,
      // username is required for username organization but optional for other one
      schema: usernameRequired
        ? usersTableSchemas.usernameSchema
        : yup.string(),
    },
    {
      field: 'status',
      label: 'Status',
      width: staffView ? 170 : 'hidden',
      editType: 'text',
      editable: false,
      hideOnAdd: true,
      hideOnEdit: true,
      renderCell: ({ row }) => {
        if (isEmpty(row.lastActivityAt)) return '';
        return (
          <Stack direction='row' gap={1} alignItems='center'>
            <StaffActivityStatus lastActivityAt={row.lastActivityAt} />
            <Typography variant='body2'>
              {formatDistanceToNow(new Date(row.lastActivityAt), {
                addSuffix: true,
              })}
            </Typography>
          </Stack>
        );
      },
      // by setting default to -5 years we are moving all no value rows at the end
      valueGetter: ({ row }) => row.lastActivityAt,
      sortComparator: statusComparator,
    },
    {
      field: 'password',
      label: 'Password',
      width: 'hidden',
      editType: 'password',
      addSchema: getPasswordSchema(org, true),
      editSchema: getPasswordSchema(org, false),
      importType: 'password',
      importSchema: getPasswordSchema(org, true),
    },
    {
      field: 'role',
      label: 'Role',
      width: 160,
      editType: 'select',
      options: getRoleOptions(isAdminRole, roleMap),
      schema: yup.string().required('User Role is required'),
      renderCell: ({ value }) =>
        roleMap[value] ? (
          <GeneralChip
            bold
            label={roleMap[value]?.displayName || ''}
            size='small'
          />
        ) : (
          ''
        ),
      valueFormatter: ({ value }) => roleMap[value]?.displayName || '',
      filterOperators: getGridStringOperators().map((filter) => ({
        ...filter,
        getApplyFilterFn: (filterItem, column) => {
          const filterValue = filterItem?.value?.toLowerCase();

          const filterFunction = filter.getApplyFilterFn(
            { ...filterItem, value: filterValue },
            column,
          );

          return (params) => {
            if (isEmpty(filterValue)) return true;
            const value = roleMap[params.value]?.displayName?.toLowerCase();
            return filterFunction?.({ ...params, value }) || false;
          };
        },
      })),
    },
    {
      field: 'org',
      label: 'Org',
      width: 'hidden',
      hideOnAdd: true,
      hideOnEdit: true,
      editType: 'select',
      options: [org ?? ''],
      importSchema: yup
        .string()
        .required('User Organization is required')
        .oneOf([org ?? '']),
    },
    {
      field: 'levelAccess',
      label: 'Level Access',
      width: 160,
      editType: 'special',
      initialValue: null,
      renderCell: ({ value }) =>
        value && <GeneralChip bold label={startCase(value)} size='small' />,
      schema: yup.string().when('role', (value: any) => {
        const role = roleMap[value];
        return role && role.templateId === 'Admin'
          ? yup.string().nullable()
          : yup.string().required('Level Access is required');
      }),
      importType: 'select',
      options: ['building', 'org', 'unit'],
      renderImportCell: (props) => (
        <GridEditSingleSelectCell {...props} value={props.value ?? ''} />
      ),
    },
    {
      field: 'unitId',
      label: 'Unit',
      width: 300,
      editType: 'special',
      initialValue: null,
      schema: yup.string().when('role', (value: any) => {
        const role = roleMap[value];
        return role && role.templateId === 'Admin'
          ? yup.string().nullable()
          : yup.string().required('Unit is required');
      }),
      renderCell: ({ value }) => {
        const role = roleMap[value];
        return role && role.templateId === 'Admin' ? (
          ''
        ) : (
          <UnitDisplayName unitId={value} unitsMap={unitsMap} />
        );
      },
      renderImportCell: (props) => (
        <ImportUnitAutocomplete org={org} {...props} />
      ),
    },
    {
      field: 'assignments',
      label: 'Assignments',
      width: 160,
      editType: 'text',
      editable: false,
      hideOnAdd: true,
      hideOnEdit: true,
      renderCell: ({ row }) => <UserAssignmentModal row={row} />,
    },
    {
      field: 'beacon',
      label: 'Beacon',
      width: 180,
      editType: 'text',
      editable: false,
      hideOnAdd: true,
      hideOnEdit: true,
      renderCell: ({ value }) => (
        <>
          <Can
            permission={{
              action: 'view',
              subject: 'virtual-care.admin.beacons',
            }}
          >
            <Link href={`/admin/beacons/${value}`}>{value}</Link>
          </Can>
          <Cannot
            permission={{
              action: 'view',
              subject: 'virtual-care.admin.beacons',
            }}
          >
            {value}
          </Cannot>
        </>
      ),
    },
    {
      field: 'mobilePhone',
      label: 'Mobile phone',
      width: textMessageAlerts ? 160 : 'hidden',
      editType: 'mobile-phone',
      editable: !!textMessageAlerts,
      schema: yup
        .string()
        .optional()
        .nullable()
        .test('validMobilePhoneNumber', (value) =>
          value ? isValidMobileNumber(value) : true,
        )
        .transform((value, originalValue) =>
          // controlled input can return originalValue as '+1' for empty number
          originalValue === '' || originalValue === '+1'
            ? null
            : formatPhoneNumberSafe(value, PhoneNumberFormat.E164),
        ),
      valueFormatter: ({ value }) =>
        value
          ? formatPhoneNumberSafe(
              value,
              PhoneNumberFormat.US_NATIONAL_OTHER_INTERNATIONAL,
            )
          : '',
      renderCell: ({ value, row }) => (
        <>
          {value && (
            <GeneralChip
              bold
              label={formatPhoneNumberSafe(
                value,
                PhoneNumberFormat.US_NATIONAL_OTHER_INTERNATIONAL,
              )}
              size='small'
            />
          )}
          {row.mobilePhoneVerified && <VerifiedIcon />}
        </>
      ),
    },
    {
      field: 'auth0Connection',
      label: 'Auth0 Connection',
      hideOnAdd: true,
      hideOnEdit: true,
      editable: false,
      width: 'hidden',
      editType: 'text',
    },
  ];
};
