import { useMemo } from 'react';
import type {
  GridEditSingleSelectCellProps,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';
import { GridEditSingleSelectCell, useGridApiContext } from '@mui/x-data-grid';
import { isEmpty, isNil } from 'lodash';

import { useUnits } from '../../../hooks/useUnits';
import { useFieldValidator } from '../hooks/useFieldValidator';

type Props = GridRenderEditCellParams<string> & {
  useDomainId?: boolean;
};

const ImportUnitSelect = (params: Props) => {
  const apiRef = useGridApiContext();

  const { id, error: validationError, disabled, colDef, useDomainId } = params;

  const { isLoading, data: units } = useUnits();

  const selectedOrg = apiRef.current.state.editRows[id]?.organization?.value;

  const valueOptions = useMemo(() => {
    if (isEmpty(selectedOrg) || !units) {
      return [];
    }

    return units
      .filter((unit) => unit.orgId === selectedOrg)
      .map((unit) => ({
        label: unit.domainId.replace(`${selectedOrg}-`, ''),
        value: useDomainId
          ? unit.domainId.replace(`${selectedOrg}-`, '')
          : unit.id,
      }))
      .sort();
  }, [units, selectedOrg]);

  const { invalidValueError } = useFieldValidator({
    skipValidation: isLoading || isNil(selectedOrg),
    params,
    valueOptions,
  });

  const selectParams: GridEditSingleSelectCellProps = {
    ...params,
    colDef: {
      ...colDef,
      valueOptions,
    },
    disabled: disabled || !selectedOrg || isLoading,
    onValueChange: () => {
      apiRef.current.setEditCellValue({
        id,
        field: 'room',
        value: '',
        debounceMs: 200,
      });
    },
    error: !!selectedOrg && (invalidValueError || !!validationError),
  };

  return <GridEditSingleSelectCell {...selectParams} />;
};

export default ImportUnitSelect;
