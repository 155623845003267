import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

interface Response {
  users: TelesittingTypes.User[];
  lastKey: string | undefined;
}

export const getOrgUsers = async (lastKey?: string): Promise<Response> => {
  try {
    const res = await Api.get<Response>('/v1/users/telesitting/allUsers', {
      params: {
        lastKey,
      },
    });

    return res?.data;
  } catch (error) {
    console.error('error requesting all users');
    throw error;
  }
};
