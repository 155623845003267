import { sortBy } from 'lodash';

import type { SortMethod } from '@inspiren-monorepo/shared-types';
import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { checkIfPrivacyMode } from './checkIfPrivacyMode';

const presort = <T extends TelesittingTypes.Room>(rooms: T[]): T[] =>
  [...rooms].sort((a, b) =>
    a.displayName.localeCompare(b.displayName, 'en', { numeric: true }),
  );

const commonSort: (<T extends TelesittingTypes.Room>(room: T) => boolean)[] = [
  // All enabled AUGis first
  (room) => Boolean(room.disable),

  // Then online AUGis
  (room) => Boolean(room.offline),

  // Fall alerts first
  (room) => room.alert !== 'fall',

  // Then all active alerts
  (room) => !room.alert,
];

const smartSort = <T extends TelesittingTypes.Room>(rooms: T[]): T[] =>
  sortBy(presort(rooms), [
    ...commonSort,

    // Sort by privacy mode
    (room) => checkIfPrivacyMode(room.virtualCurtainAt),

    // Sort by "in view" status
    (room) => room.lastLoc && ['none', 'outside'].includes(room.lastLoc),

    // Sort by count
    (room) => room.staffCount,

    // Sort by last checked
    (room) => {
      const { lastCheckedAt } = room;
      return lastCheckedAt ? Date.parse(lastCheckedAt) : null;
    },
  ]);

const sortByFallRisk = <T extends TelesittingTypes.Room>(rooms: T[]): T[] =>
  sortBy(presort(rooms), [
    ...commonSort,
    // Sort by fall risk
    (room) => {
      switch (room.fallRiskLevel) {
        case 'high':
          return 1;
        case 'low':
          return 2;
        case 'nightLow':
          return 3;
        default:
          return 4;
      }
    },
  ]);

const sortByAlpha = <T extends TelesittingTypes.Room>(rooms: T[]): T[] =>
  sortBy(presort(rooms), commonSort);

export const sortRooms = <T extends TelesittingTypes.Room>(
  rooms: T[],
  sortMethod?: SortMethod,
): T[] => {
  switch (sortMethod) {
    case 'smart':
      return smartSort(rooms);
    case 'numeric':
      return sortByAlpha(rooms);
    case 'fallRisk':
      return sortByFallRisk(rooms);
    default:
      return presort(rooms);
  }
};
