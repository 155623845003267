import { useMemo } from 'react';

import { useOrganizationRolesMap } from './useOrganizationRolesMap';

import { useCurrentUser } from '../HOC/CurrentUserContextProvider';

export const useIsAdmin = () => {
  const { user } = useCurrentUser();

  const { data: roleMap = {}, isLoading } = useOrganizationRolesMap(
    user?.orgId,
  );

  const isAdmin = useMemo(
    () => Boolean(user && roleMap[user?.roleId]?.templateId === 'Admin'),
    [user, roleMap],
  );

  return { isAdmin, isAdminLoading: isLoading };
};
