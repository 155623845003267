import type React from 'react';
import { useCallback, useState } from 'react';
import { IconButton } from '@mui/material';
import { VolumeUp } from '@mui/icons-material';

import { RoomAudibleMessageModal } from './RoomAudibleMessageModal/RoomAudibleMessageModal';
import { useAudibleMessages } from './hooks/useAudibleMessages';

import { sendAmpEvent } from '../../utility/amplitude';
import { extractOrgFromRoomId } from '../../utility/helpers/id';

interface Props {
  roomId: string;
  roomName: string;
  baseId?: string;
}

export const RoomAudibleMessagesModalButton = ({
  roomId,
  roomName,
  baseId,
}: Props) => {
  const { audibleMessages, languages } = useAudibleMessages(
    extractOrgFromRoomId(roomId),
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setOpen(true);
      sendAmpEvent('Open Audible Message Menu', { roomId });
    },
    [anchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  return (
    <>
      <IconButton
        sx={{
          backgroundColor: 'grey.800',
          color: '#fff',
          height: 36,
          width: 36,
          ':hover': {
            backgroundColor: 'grey.900',
          },
          opacity: 0.8,
        }}
        onClick={toggleOpen}
      >
        <VolumeUp fontSize='medium' />
      </IconButton>
      <RoomAudibleMessageModal
        open={open}
        roomId={roomId}
        roomName={roomName}
        baseId={baseId!}
        languages={languages}
        audibleMessages={audibleMessages}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </>
  );
};
