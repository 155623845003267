import { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { isEmpty, keyBy, sortBy } from 'lodash';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { getUsersTableQuery } from '../data-access/getUsersTableQuery';

import type { UsersTableQueryResponse } from '../data-access/getUsersTableQuery';
import type {
  InfiniteData,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';

type Options = Omit<
  UseInfiniteQueryOptions<
    UsersTableQueryResponse,
    Error,
    InfiniteData<UsersTableQueryResponse, unknown>,
    UsersTableQueryResponse,
    string[],
    string | undefined
  >,
  'queryKey' | 'queryFn' | 'enabled' | 'getNextPageParam' | 'initialPageParam'
>;

export const useAdminUsers = (
  selectedOrg?: string | undefined,
  options: Options = {},
) => {
  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery({
    ...options,
    queryKey: ['users', selectedOrg!],
    queryFn: ({ pageParam }) => getUsersTableQuery(selectedOrg!, pageParam),
    enabled: !isEmpty(selectedOrg),
    getNextPageParam: (lastPage) => lastPage.lastKey,
    initialPageParam: undefined as string | undefined,
  });

  const users = useMemo(() => {
    const adminToolUsers: AdminTypes.User[] = [];
    if (!data?.pages) return adminToolUsers;

    data.pages.forEach((page) => {
      adminToolUsers.push(...page.users);
    });

    return adminToolUsers;
  }, [data]);

  const usersById = useMemo(
    () => keyBy(users, (user) => user.domainId),
    [users],
  );

  const userIds = useMemo(
    () =>
      (
        sortBy(
          users,
          ({ firstName, lastName }) => `${firstName} ${lastName}`,
        ) || []
      ).map((user) => user.domainId),
    [users],
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, data]);

  return { users, isFetching, usersById, userIds };
};
