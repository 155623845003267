import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

import type { FallRiskLevel } from '../../../../../../types';

export const updateFallRisk = async ({
  roomId,
  fallRiskLevel,
}: {
  roomId: string;
  fallRiskLevel: FallRiskLevel;
}) =>
  Api.put<never, never, TelesittingTypes.RoomEdit>(
    `/telesitting/v1/rooms/${roomId}`,
    {
      fallRiskLevel,
      fallRiskLastSet: 'VIRTUAL-CARE',
    },
  );
