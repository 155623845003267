import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

export const toggleBathroomAlerts = async (
  roomId: string,
  bathroomAlertsEnabled: boolean,
) =>
  Api.put<never, never, TelesittingTypes.RoomEdit>(
    `/telesitting/v1/rooms/${roomId}`,
    {
      bathroomAlertsEnabled,
    },
  );
