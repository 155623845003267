import { Typography } from '@mui/material';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

interface Props {
  unitId: string;
  unitsMap: Record<string, AdminTypes.UnitDto>;
}

const UnitDisplayName = ({ unitId, unitsMap }: Props) => {
  if (!unitsMap[unitId]) return null;

  const unit = unitsMap[unitId];
  return (
    <Typography variant='body2'>
      {unit.address} {unit.displayName || unit.domainId.split('-').pop()}
    </Typography>
  );
};

export default UnitDisplayName;
