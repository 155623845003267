import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const putUser = async (
  user: { userId: string } & Partial<AdminTypes.NewUser>,
) => {
  const res = await Api.put<AdminTypes.User>('/admin/v1/users', user);
  return res.data;
};
