import { useCallback, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import type { GridColDef } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';

import { useUserAssignments } from '../hooks/useUserAssignments';

import type { UserAssignment } from '../data-access/getUserAssignments';

type UserRowDetailsProps = {
  row: {
    id: string;
    userId: string;
    assignmentsCount: number;
    firstName: string;
    lastName: string;
  };
};

const columns: GridColDef<UserAssignment>[] = [
  { field: 'roomName', headerName: 'Name', width: 180 },
  { field: 'unitName', headerName: 'Unit', width: 180 },
];

export const UserAssignmentModal = ({ row }: UserRowDetailsProps) => {
  const [open, setOpen] = useState(false);

  const { isLoading, data: assignments } = useUserAssignments(row?.userId);

  const handleOpenModal = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Typography
        variant='body2'
        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={handleOpenModal}
      >
        Show assignments
      </Typography>
      {open && (
        <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth='md'>
          <DialogTitle>
            User Assignments: {row?.firstName} {row?.lastName}
            <IconButton
              aria-label='close'
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DataGrid
              rows={assignments || []}
              columns={columns}
              initialState={{
                pagination: {
                  pageSize: 10,
                },
              }}
              loading={isLoading}
              getRowId={(row) => row.roomId}
              autoHeight
              localeText={{
                noRowsLabel: 'No assignments',
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
