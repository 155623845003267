import { useMemo } from 'react';
import { skipToken, useQuery } from '@tanstack/react-query';
import { intervalToDuration } from 'date-fns';

import { useOrganizationRolesMap } from '../../../hooks/useOrganizationRolesMap';
import { getStaffEvents } from '../data-access/getStaffEvents';
import { formatDurationConcise } from '../helpers/formatDurationConcise';
import { useEventReviewStore } from '../store/EventReviewStore';

import type { StaffEventRes, UserRole } from '../../../../types';

export const useStaffEvents = () => {
  const { selectedRoom, selectedOrg, startDate, endDate } =
    useEventReviewStore();

  const roomId = selectedRoom?.domainId;
  const { data: roleMap = {} } = useOrganizationRolesMap(selectedOrg?.id);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['eventReview', 'staffEvents', roomId, startDate, endDate],
    queryFn:
      roomId && startDate && endDate
        ? async () => {
            const events = await getStaffEvents(
              roomId,
              startDate.toISOString(),
              endDate.toISOString(),
            );

            return events?.map((item) => {
              if (!item.staffFirstName) {
                return {
                  ...item,
                  staffFirstName: 'Staff',
                  staffLastName: 'Member',
                  staffRole: '' as UserRole,
                };
              }

              return item;
            });
          }
        : skipToken,
  });

  const staffData = useMemo(() => {
    if (!data) return [];

    return data?.map((event: StaffEventRes) => ({
      event: 'Staff Event',
      time: event.timeIn ? new Date(event.timeIn) : null,
      timeIn: event.timeIn ? new Date(event.timeIn) : null,
      timeOut: event.timeOut ? new Date(event.timeOut) : null,
      name:
        event.staff === 'Unassigned'
          ? 'Unassigned'
          : `${event.staffFirstName} ${event.staffLastName} - ${roleMap[event.staffRoleId || ''].displayName}`,
      duration:
        event.timeIn &&
        event.timeOut &&
        formatDurationConcise(
          intervalToDuration({
            start: new Date(event.timeIn),
            end: new Date(event.timeOut),
          }),
        ),
      id: `${event.timeIn}-${event.beaconEntryId || event.beacon || ''}`,
    }));
  }, [data, roleMap]);

  return { data: staffData, isLoading, isError };
};
