import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { clearSentryUser, setSentryUser } from '../utility/sentry';

type Props = {
  children: JSX.Element;
};

const CurrentUserContext = createContext<{
  user?: TelesittingTypes.User | null;
  setUser: (user?: TelesittingTypes.User | null) => void;
}>({
  user: null,
  setUser: () => {
    /* empty */
  },
});

export const useCurrentUser = () => useContext(CurrentUserContext);

export const CurrentUserContextProvider = ({ children }: Props) => {
  const [user, setUser] = useState<TelesittingTypes.User | null | undefined>(
    null,
  );

  useEffect(() => {
    if (user) {
      setSentryUser(user);
    } else {
      clearSentryUser();
    }
  }, [user]);

  const value = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user, setUser],
  );

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};
