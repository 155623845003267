import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export interface UsersTableQueryResponse {
  users: AdminTypes.User[];
  lastKey: string | undefined;
}

export async function getUsersTableQuery(org: string, lastKey?: string) {
  return Api.get<UsersTableQueryResponse>('admin/v1/users/table', {
    params: {
      org,
      lastKey,
    },
  }).then((res) => res.data);
}
